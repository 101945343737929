@import '~#/components/Item/AssetItem/assetItem';

@import '~#/theme/vw';

.background {
  background-position: center;

  &.content-container {
    .title {
      color: rgb(53, 159, 189);
    }

    .genres {
      font-size: vw(25px);
    }
  }
}

.continue-watching-item {
  position: relative;

  .progress {
    background-color: rgba($light-grey, .5);
    border-top: vw(1px) solid $grey;
    height: vw(5px);
    position: relative;
    width: 100%;

    > span {
      background-color: $button-border-grey;
      display: block;
      height: 100%;
    }
  }
}
