@import '~#/theme/main';

.game-container {
  background: linear-gradient(90deg, #002d62, #5a2d81);
  border-radius: $card-corner-radius-xl;
  box-shadow:
    $card-content-effects-focus-x-axis
    $card-content-effects-focus-y-axis
    $card-content-effects-focus-blur $card-content-effects-focus-spread $card-content-effects-focus-drop-shadow;
  height: 100%;
}

.title-section {
  font-family: $display-small-font-family;
  text-align: center;

  .title-header {
    color: $card-content-text-focus;
    font-family: $display-small-font-family;
    font-size: vw($typography-font-size-48px);
    font-weight: 600;
    letter-spacing: $display-small-letter-spacing;
    line-height: 120%;
    margin-bottom: 0;
    margin-top: vw(48px);
  }

  .title-date {
    color: $card-content-text-focus;
    font-family: $label-medium-font-family;
    font-size: vw($label-medium-font-size);
    font-weight: 500;
    letter-spacing: $label-medium-letter-spacing;
    line-height: 120%;
    margin-bottom: 0;
    margin-top: $card-spacing-item-item-sm;
  }
}

.background-left {
  height: $card-sizing-team-logo-logo-background-height-lg;
  left: 40%;
  margin-left: -$card-sizing-team-logo-logo-background-width-lg;
  opacity: .05;
  overflow: hidden;
  position: absolute;
  width: $card-sizing-team-logo-logo-background-width-lg;
}

.background-right {
  height: $card-sizing-team-logo-logo-background-height-lg;
  left: 140%;
  margin-left: -$card-sizing-team-logo-logo-background-width-lg;
  opacity: .05;
  overflow: hidden;
  position: absolute;
  width: $card-sizing-team-logo-logo-background-width-lg;
}

.team-section {
  display: flex;
  gap: $card-spacing-item-item-5xl;
  justify-content: center;
}

.wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.background {
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  @include browser($legacy-devices) {
    border-radius: 12px !important;
  }
}

.team-logo {
  background-repeat: no-repeat;
  background-size: contain;
  height: vw($card-sizing-team-logo-logo-foreground-width-lg);
  width: vw($card-sizing-team-logo-logo-foreground-width-lg);
}

.team-container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: $card-spacing-item-item-lg;

  span {
    color: $card-content-text-focus;
    font-family: $label-medium-font-family;
    font-size: $label-medium-font-size;
    font-style: normal;
    font-weight: 600;
    letter-spacing: $label-medium-letter-spacing;
    line-height: 120%;
  }
}
