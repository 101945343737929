@import '~#/theme/main';

.card-height {
  height: vw(452px);
}

.background {
  align-items: center;
  background-image: linear-gradient(to right, #2d2d35, #44454d);
  background-size: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  box-shadow: var(--card-9by16-default-box-shadow);
  display: flex;
  justify-content: center;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 100%;

  img {
    height: vw(380px);
    object-fit: contain;
    width: vw(380px);
  }

  @include browser($legacy-devices) {
    border-radius: 12px !important;
  }
}

.brand {
  background-color: rgba(11, 11, 11, .5);
  border-radius: 0 0 0 12px;
  color: #ebebeb;
  font-size: vw(28px);
  font-weight: 500;
  height: 40px;
  padding: 16px;
  position: absolute;
  right: 0;
  top: 0;
}

.merch-details {
  background-color: #303030;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  bottom: 0;
  padding: vw(24px);

  p {
    font-size: vw(28px);
    font-weight: 300;
    line-height: vw(35px);
    margin: 0;

    &:first-of-type {
      font-size: vw(32px);
      font-weight: 500;
      line-height: vw(42px);
      margin-bottom: vw(8px);
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
}
