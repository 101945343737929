@import '~#/theme/main';

.button {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-transform: uppercase;

  &.lowercase {
    text-transform: none;
  }

  @include button-all;

  &.reverse {

    @include button-reverse;
  }

  .icon-only {
    margin: 0 !important;
  }

  @include button-primary;

  &.primary {
    &.focused {

      @include button-primary-focus;
    }

    &:disabled {

      @include button-primary-disabled;
    }
  }

  &.secondary {

    @include button-secondary;

    &.focused {

      @include button-secondary-focus;
    }

    &:disabled {

      @include button-secondary-disabled;
    }
  }
}
