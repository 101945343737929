@import '~#/theme/main';

.container {
  align-items: center;
  color: var(--metadata-text-fill);
  display: flex;
  flex-direction: row;
  font: var(--metadata-text-typography);
  justify-content: space-between;
  padding-top: vw(24px);

  div {
    overflow: hidden;

    &:first-of-type {
      flex: 2;
    }

    &:last-of-type {
      flex: 2;
      opacity: .6;
      text-align: right;
    }
  }

  p {
    font-size: vw(28px);
    font-weight: 300;
    line-height: vw(34px);
    margin: 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  .dot {
    background-color: var(--metadata-bullet-fill);
    border-radius: 50%;
    height: var(--metadata-bullet-sizing);
    width: var(--metadata-bullet-sizing);
  }
}
