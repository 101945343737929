:root {
  --button-regular-spacing-item-gap: 12px;
  --button-regular-spacing-padding-horizontal: 40px;
  --button-regular-spacing-padding-vertical: 24px;
  --button-border-radius: 0;
  --button-width: 216px;
  --button-icon1: 100px;
  --button-icon-secondary: 70px;
  --button-height: 88px;
  --button-default: 2px solid #fff;
  --button-focus: 8px solid #fff;
  --button-disabled: 2px solid rgba(255,255,255,0.5);
  --button-text-primary-container-default-fill: #334155;
  --button-text-primary-container-default-border-width: 0;
  --button-text-primary-container-default-border-radius: 4px;
  --button-text-primary-container-focus-fill: #0064FF;
  --button-text-primary-container-focus-border-width: 0;
  --button-text-primary-container-focus-border-radius: 4px;
  --button-text-primary-container-disabled-fill: rgba(51,65,85,0.6);
  --button-text-primary-container-disabled-border-width: 0;
  --button-text-primary-container-disabled-border-radius: 4px;
  --button-text-primary-typography-default-fill: #fff;
  --button-text-primary-typography-default-typography: 500 24px/1.1 Outfit;
  --button-text-primary-typography-focus-fill: #fff;
  --button-text-primary-typography-focus-typography: 500 24px/1.1 Outfit;
  --button-text-primary-typography-disabled-fill: rgba(253,253,253,0.8);
  --button-text-primary-typography-disabled-typography: 500 24px/1.1 Outfit;
  --button-text-primary-icon-default-fill: #fff;
  --button-text-primary-icon-default-sizing: 40px;
  --button-text-primary-icon-focus-fill: #fff;
  --button-text-primary-icon-focus-sizing: 40px;
  --button-text-primary-icon-disabled-fill: rgba(253,253,253,0.8);
  --button-text-primary-icon-disabled-sizing: 40px;
  --button-text-secondary-container-default-fill: #002eff;
  --button-text-secondary-container-default-border-radius: 4px;
  --button-text-secondary-container-default-border: 2px solid #fff;
  --button-text-secondary-container-focus-fill: #0064FF;
  --button-text-secondary-container-focus-border-radius: 4px;
  --button-text-secondary-container-focus-border: 8px solid #fff;
  --button-text-secondary-container-disabled-fill: #002eff;
  --button-text-secondary-container-disabled-border-radius: 4px;
  --button-text-secondary-container-disabled-border: 2px solid rgba(255,255,255,0.5);
  --button-text-secondary-typography-default-typography: 500 24px/1.1 Outfit;
  --button-text-secondary-typography-default-fill: #fff;
  --button-text-secondary-typography-focus-typography: 500 24px/1.1 Outfit;
  --button-text-secondary-typography-focus-fill: #fff;
  --button-text-secondary-typography-disabled-typography: 500 24px/1.1 Outfit;
  --button-text-secondary-typography-disabled-fill: #fff;
  --button-text-secondary-icon-default-fill: #fff;
  --button-text-secondary-icon-default-sizing: 40px;
  --button-text-secondary-icon-focus-fill: #fff;
  --button-text-secondary-icon-focus-sizing: 40px;
  --button-text-secondary-icon-disabled-fill: #fff;
  --button-text-secondary-icon-disabled-sizing: 40px;
  --button-text-all-horizontal-padding: 40px;
  --button-text-all-vertical-padding: 24px;
  --button-text-all-item-spacing: 16px;
  --button-icon-primary-container-default-fill: #334155;
  --button-icon-primary-container-default-border-width: 0;
  --button-icon-primary-container-default-sizing: 100px;
  --button-icon-primary-container-focus-fill: #0064FF;
  --button-icon-primary-container-focus-border-width: 0;
  --button-icon-primary-container-focus-sizing: 100px;
  --button-icon-primary-container-disabled-fill: rgba(51,65,85,0.6);
  --button-icon-primary-container-disabled-border-width: 0;
  --button-icon-primary-container-disabled-sizing: 100px;
  --button-icon-primary-icon-default-fill: #fff;
  --button-icon-primary-icon-default-sizing: 40px;
  --button-icon-primary-icon-focus-fill: #fff;
  --button-icon-primary-icon-focus-sizing: 40px;
  --button-icon-primary-icon-disabled-fill: rgba(253,253,253,0.8);
  --button-icon-primary-icon-disabled-sizing: 40px;
  --button-icon-all-horizontal-padding: 24px;
  --button-icon-all-vertical-padding: 24px;
  --button-icon-all-border-radius: 1000px;
  --button-icon-secondary-container-default-fill: #334155;
  --button-icon-secondary-container-default-border-width: 0;
  --button-icon-secondary-container-default-sizing: 70px;
  --button-icon-secondary-container-focus-fill: #0064FF;
  --button-icon-secondary-container-focus-border-width: 0;
  --button-icon-secondary-container-focus-sizing: 70px;
  --button-icon-secondary-container-disabled-fill: rgba(51,65,85,0.6);
  --button-icon-secondary-container-disabled-border-width: 0;
  --button-icon-secondary-container-disabled-sizing: 70px;
  --button-icon-secondary-icon-default-fill: #fff;
  --button-icon-secondary-icon-default-sizing: 40px;
  --button-icon-secondary-icon-focus-fill: #fff;
  --button-icon-secondary-icon-focus-sizing: 40px;
  --button-icon-secondary-icon-disabled-fill: rgba(253,253,253,0.8);
  --button-icon-secondary-icon-disabled-sizing: 40px;
  --units-2: 2;
  --units-4: 4;
  --units-6: 6;
  --units-8: 8;
  --units-12: 12;
  --units-16: 16;
  --units-24: 24;
  --units-32: 32;
  --units-40: 40;
  --units-48: 48;
  --units-56: 56;
  --units-64: 64;
  --units-72: 72;
  --units-88: 88;
  --units-100: 100;
  --units-140: 140;
  --units-232: 232;
  --units-288: 288;
  --units-320: 320;
  --units-400: 400;
  --units-512: 512;
  --spacing-global-quarck: 4px;
  --spacing-global-nano: 8px;
  --spacing-global-xxs: 12px;
  --spacing-global-xs: 16px;
  --spacing-global-sm: 24px;
  --spacing-global-md: 40px;
  --spacing-global-lg: 56px;
  --spacing-global-xl: 80px;
  --spacing-global-xxl: 112px;
  --spacing-global-3xl: 144px;
  --font-family-brand: Outfit;
  --font-family-apple: 'SF Pro Text';
  --font-family-android: Roboto;
  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-bold: 700;
  --font-line-height-tight: 1.1;
  --font-line-height-default: 1.3;
  --font-line-height-distant: 1.5;
  --font-letter-spacing-default: 0.2;
  --font-letter-spacing-tight: -0.2;
  --font-letter-spacing-distant: 1.5;
  --font-paragraph-spacing-default: 0;
  --font-paragraph-spacing-distant: 24;
  --font-size-8: 8px;
  --font-size-10: 10px;
  --font-size-12: 12px;
  --font-size-14: 14px;
  --font-size-16: 16px;
  --font-size-18: 18px;
  --font-size-20: 20px;
  --font-size-24: 24px;
  --font-size-26: 26px;
  --font-size-32: 32px;
  --font-size-40: 40px;
  --font-size-48: 48px;
  --font-size-56: 56px;
  --font-size-64: 64px;
  --font-size-72: 72px;
  --typo-tv-heading-1-font-family: Outfit;
  --typo-tv-heading-1-font-weight: 400;
  --typo-tv-heading-1-line-height: 1.1;
  --typo-tv-heading-1-font-size: 72px;
  --typo-tv-heading-1-letter-spacing: 0.2;
  --typo-tv-heading-1-paragraph-spacing: 0;
  --typo-tv-heading-2-font-family: Outfit;
  --typo-tv-heading-2-font-weight: 500;
  --typo-tv-heading-2-line-height: 1.1;
  --typo-tv-heading-2-font-size: 56px;
  --typo-tv-heading-2-letter-spacing: 0.2;
  --typo-tv-heading-2-paragraph-spacing: 0;
  --typo-tv-heading-3-font-family: Outfit;
  --typo-tv-heading-3-font-weight: 700;
  --typo-tv-heading-3-line-height: 1.3;
  --typo-tv-heading-3-font-size: 48px;
  --typo-tv-heading-3-letter-spacing: 0.2;
  --typo-tv-heading-3-paragraph-spacing: 0;
  --typo-tv-heading-1a: 400 72px/1.1 Outfit;
  --typo-tv-title-large-font-family: Outfit;
  --typo-tv-title-large-font-weight: 500;
  --typo-tv-title-large-line-height: 1.3;
  --typo-tv-title-large-font-size: 40px;
  --typo-tv-title-large-letter-spacing: 0.2;
  --typo-tv-title-large-paragraph-spacing: 0;
  --typo-tv-title-medium-font-family: Outfit;
  --typo-tv-title-medium-font-weight: 500;
  --typo-tv-title-medium-line-height: 1.3;
  --typo-tv-title-medium-font-size: 32px;
  --typo-tv-title-medium-letter-spacing: 0.2;
  --typo-tv-title-medium-paragraph-spacing: 0;
  --typo-tv-title-small-font-family: Outfit;
  --typo-tv-title-small-font-weight: 700;
  --typo-tv-title-small-line-height: 1.3;
  --typo-tv-title-small-font-size: 20px;
  --typo-tv-title-small-letter-spacing: 0.2;
  --typo-tv-title-small-paragraph-spacing: 0;
  --typo-tv-title-large1: 500 40px/1.3 Outfit;
  --typo-tv-button-default-font-family: Outfit;
  --typo-tv-button-default-font-weight: 700;
  --typo-tv-button-default-line-height: 1.1;
  --typo-tv-button-default-font-size: 24px;
  --typo-tv-button-default-letter-spacing: 1.5;
  --typo-tv-button-default-paragraph-spacing: 0;
  --typo-tv-button-default1: 500 24px/1.1 Outfit;
  --typo-tv-button-nav: 500 26px/1.1 Outfit;
  --typo-tv-body-default-font-family: Outfit;
  --typo-tv-body-default-font-weight: 400;
  --typo-tv-body-default-line-height: 1.3;
  --typo-tv-body-default-font-size: 24px;
  --typo-tv-body-default-letter-spacing: 1.5;
  --typo-tv-body-default-paragraph-spacing: 0;
  --typo-tv-body-small-font-family: Outfit;
  --typo-tv-body-small-font-weight: 400;
  --typo-tv-body-small-line-height: 1.3;
  --typo-tv-body-small-font-size: 18px;
  --typo-tv-body-small-letter-spacing: 1.5;
  --typo-tv-body-small-paragraph-spacing: 0;
  --typo-tv-body-small1: 700 18px/1.3 Outfit; /* was 400 */
  --typo-tv-body-default1: 700 24px/1.3 Outfit; /* was 400 */
  --typo-tv-caption-default-font-family: Outfit;
  --typo-tv-caption-default-font-weight: 400;
  --typo-tv-caption-default-line-height: 1.3;
  --typo-tv-caption-default-font-size: 16px;
  --typo-tv-caption-default-letter-spacing: 1.5;
  --typo-tv-caption-default-paragraph-spacing: 0;
  --typo-tv-caption-emphasis-font-family: Outfit;
  --typo-tv-caption-emphasis-font-weight: 700;
  --typo-tv-caption-emphasis-line-height: 1.3;
  --typo-tv-caption-emphasis-font-size: 16px;
  --typo-tv-caption-emphasis-letter-spacing: 1.5;
  --typo-tv-caption-emphasis-paragraph-spacing: 0;
  --border-radius-10: 10px;
  --border-radius-none: 0;
  --border-radius-small: 4px;
  --border-radius-medium: 8px;
  --border-radius-large: 16px;
  --border-radius-xlarge: 24px;
  --border-radius-pill: 1000px;
  --border-width-none: 0;
  --border-width-thin: 1px;
  --border-width-default: 2px;
  --border-width-thick: 4px;
  --border-width-heavy: 8px;
  --progressbar-card-spacing-padding-horizontal: 16px;
  --progressbar-card-spacing-padding-vertical: 12px;
  --progressbar-card-height: 12px;
  --progressbar-player-width: 1140px;
  --progressbar-player-height: 12px;
  --card-item-portrait-height: 512px;
  --card-item-portrait-width: 320px;
  --card-item-portrait-border-radius: 16px;
  --card-item-landscape-height: 232px;
  --card-item-landscape-width: 400px;
  --card-item-landscape-border-radius: 16px;
  --card-item-highlighted-landscape-border-radius: 16px;
  --card-item-highlighted-portrait-border-radius: 16px;
  --card-item-label-fill: #fff;
  --label-width: 176px;
  --label-height: 88px;
  --tab-width: 112px;
  --tab-height: 64px;
  --input-search-width: 238px;
  --input-search-height: 48px;
  --input-spacing-item-gap: 8px;
  --input-spacing-padding-horizontal: 24px;
  --input-spacing-padding-vertical: 24px;
  --input-default-color: #64748B;
  --input-default-width: 2px;
  --input-default-style: solid;
  --input-focused-color: #0064FF;
  --input-focused-width: 2px;
  --input-focused-style: solid;
  --input-typing-color: #0064FF;
  --input-typing-width: 2px;
  --input-typing-style: solid;
  --input-entered-color: #0064FF;
  --input-error-color: #f00;
  --input-error-width: 2px;
  --input-error-style: solid;
  --input-success-color: #00ff58;
  --input-success-width: 2px;
  --input-success-style: solid;
  --input-disabled-color: #475569;
  --input-disabled-width: 2px;
  --input-disabled-style: solid;
  --input-container-default-vertical-padding: 24px;
  --input-container-default-horizontal-padding: 24px;
  --input-container-default-border-radius: 4px;
  --input-container-default-min-width: 320;
  --input-container-focused-vertical-padding: 24px;
  --input-container-focused-horizontal-padding: 24px;
  --input-container-focused-border-radius: 4px;
  --input-container-focused-min-width: 320;
  --input-container-typing-vertical-padding: 24px;
  --input-container-typing-horizontal-padding: 24px;
  --input-container-typing-border-radius: 4px;
  --input-container-typing-min-width: 320;
  --input-container-entered-vertical-padding: 24px;
  --input-container-entered-horizontal-padding: 24px;
  --input-container-entered-border-radius: 4px;
  --input-container-entered-min-width: 320;
  --input-container-error-vertical-padding: 24px;
  --input-container-error-horizontal-padding: 24px;
  --input-container-error-border-radius: 4px;
  --input-container-error-min-width: 320;
  --input-container-success-vertical-padding: 24px;
  --input-container-success-horizontal-padding: 24px;
  --input-container-success-border-radius: 4px;
  --input-container-success-min-width: 320;
  --input-container-disabled-vertical-padding: 24px;
  --input-container-disabled-horizontal-padding: 24px;
  --input-container-disabled-border-radius: 4px;
  --input-container-disabled-min-width: 320;
  --input-container-disabled-fill: #475569;
  --input-container-default1-border: 2px solid #fff;
  --input-container-focus-border: 2px solid #0064FF;
  --input-container-typing1-border: 2px solid #0064FF;
  --input-container-entered1-border: 2px solid #fff;
  --input-container-error1-border: 2px solid #f00;
  --input-container-success1-border: 2px solid #00ff1f;
  --input-container-disabled1-border: 2px solid rgba(255,255,255,0.5);
  --input-container-all-vertical-padding: 24px;
  --input-container-all-horizontal-padding: 24px;
  --input-container-all-border-radius: 4px;
  --input-container-all-min-width: 320;
  --input-text-default-fill: rgba(255,255,255,0.4);
  --input-text-typing-fill: #fff;
  --input-text-entered-fill: #fff;
  --input-text-error-fill: #fff;
  --input-text-success-fill: #fff;
  --input-text-disabled-fill: rgba(255,255,255,0.4);
  --input-text-default1-typography: 700 24px/1.3 Outfit;
  --input-text-default1-fill: rgba(255,255,255,0.4);
  --input-text-typing1-typography: 700 24px/1.3 Outfit;
  --input-text-typing1-fill: #fff;
  --input-text-entered1-typography: 700 24px/1.3 Outfit;
  --input-text-entered1-fill: #fff;
  --input-text-error1-typography: 700 24px/1.3 Outfit;
  --input-text-error1-fill: #fff;
  --input-text-success1-typography: 700 24px/1.3 Outfit;
  --input-text-success1-fill: #fff;
  --input-text-disabled1-typography: 700 24px/1.3 Outfit;
  --input-text-disabled1-fill: rgba(255,255,255,0.4);
  --input-label-default-fill: #fff;
  --input-label-focused-fill: #fff;
  --input-label-typing-fill: #fff;
  --input-label-error-fill: #fff;
  --input-label-success-fill: #fff;
  --input-label-disabled-fill: #fff;
  --input-label-default1-typography: 700 18px/1.3 Outfit;
  --input-label-default1-fill: #fff;
  --input-label-focus-typography: 700 18px/1.3 Outfit;
  --input-label-focus-fill: #fff;
  --input-label-error1-typography: 700 18px/1.3 Outfit;
  --input-label-error1-fill: #fff;
  --input-label-success1-typography: 700 18px/1.3 Outfit;
  --input-label-success1-fill: #fff;
  --input-label-disabled1-typography: 700 18px/1.3 Outfit;
  --input-label-disabled1-fill: #fff;
  --input-support-default-fill: #fff;
  --input-support-focused-fill: #fff;
  --input-support-typing-fill: #fff;
  --input-support-entered-fill: #fff;
  --input-support-error-fill: #fff;
  --input-support-success-fill: #fff;
  --input-support-disabled-fill: #fff;
  --input-support-default1-typography: 700 18px/1.3 Outfit;
  --input-support-default1-fill: #fff;
  --input-support-focus-typography: 700 18px/1.3 Outfit;
  --input-support-focus-fill: #fff;
  --input-support-typing1-typography: 700 18px/1.3 Outfit;
  --input-support-typing1-fill: #fff;
  --input-support-entered1-typography: 700 18px/1.3 Outfit;
  --input-support-entered1-fill: #fff;
  --input-support-error1-typography: 700 18px/1.3 Outfit;
  --input-support-error1-fill: #fff;
  --input-support-success1-typography: 700 18px/1.3 Outfit;
  --input-support-success1-fill: #fff;
  --input-support-disabled1-typography: 700 18px/1.3 Outfit;
  --input-support-disabled1-fill: #fff;
  --input-icon-error-sizing: 40px;
  --input-icon-error-fill: #f00;
  --input-icon-success-sizing: 40px;
  --input-icon-success-fill: #00ff58;
  --input-icon-error1-sizing: 40px;
  --input-icon-error1-fill: #f00;
  --input-icon-success1-sizing: 40px;
  --input-icon-success1-fill: #00ff58;
  --input-default1: 2px solid #fff;
  --input-focus: 2px solid #0064FF;
  --input-typing1: 2px solid #0064FF;
  --input-entered1: 2px solid #fff;
  --input-error1: 2px solid #f00;
  --input-success1: 2px solid #00ff1f;
  --input-disabled1: 2px solid rgba(255,255,255,0.5);
  --icon-card: 88px;
  --icon-button: 40px;
  --icon-metadata: 32px;
  --icon-player-play: 140px;
  --icon-player-forward-rewind: 100px;
  --icon-player-subtitle: 60px;
  --hero-width: 1920px;
  --hero-height: 880px;
  --keyboard-large-width: 260px;
  --keyboard-large-height: 64px;
  --keyboard-button-container-regular-fill: rgba(255,255,255,0.2);
  --keyboard-button-container-regular-height: 64px;
  --keyboard-button-container-regular-width: 56px;
  --keyboard-button-container-wide-height: 64px;
  --keyboard-button-container-wide-width: 260px;
  --keyboard-button-container-wide-fill: #1E293B;
  --keyboard-button-container-highlighted-fill: #0F172A;
  --keyboard-button-container-small-default-fill: rgba(255,255,255,0.2);
  --keyboard-button-container-small-default-height: 64px;
  --keyboard-button-container-small-default-width: 56px;
  --keyboard-button-container-small-focus-fill: #0064FF;
  --keyboard-button-container-small-focus-height: 76.8px;
  --keyboard-button-container-small-focus-width: 67.2px;
  --keyboard-button-container-medium-default-fill: rgba(255,255,255,0.2);
  --keyboard-button-container-medium-default-height: 64px;
  --keyboard-button-container-medium-default-width: 124px;
  --keyboard-button-container-medium-focus-fill: #0064FF;
  --keyboard-button-container-medium-focus-height: 76.8px;
  --keyboard-button-container-medium-focus-width: 148.8px;
  --keyboard-button-container-large-default-fill: rgba(255,255,255,0.2);
  --keyboard-button-container-large-default-height: 64px;
  --keyboard-button-container-large-default-width: 260px;
  --keyboard-button-container-large-focus-fill: #0064FF;
  --keyboard-button-container-large-focus-height: 70.4px;
  --keyboard-button-container-large-focus-width: 286px;
  --keyboard-button-letters-fill: #fff;
  --keyboard-button-icon-height: 40px;
  --keyboard-button-icon-width: 40px;
  --keyboard-button-icon-fill: #fff;
  --keyboard-button-typography-fill: #fff;
  --keyboard-button-typography-typography: 500 24px/1.1 Outfit;
  --keyboard-button-icon1-sizing: 40px;
  --keyboard-button-icon1-fill: #fff;
  --keyboard-small-width: 56px;
  --keyboard-small-height: 64px;
  --keyboard-medium-width: 124px;
  --keyboard-medium-height: 64px;
  --scrollbar-width: 16px;
  --scrollbar-height: 372px;
  --soundbar-width: 112px;
  --soundbar-height: 8px;
  --avatar-default: 288px;
  --avatar-container-default-sizing: 288px;
  --avatar-container-default-fill: #000;
  --avatar-container-highlighted-sizing: 288px;
  --avatar-container-highlighted-fill: #000;
  --avatar-container-default1-sizing: 288px;
  --avatar-container-default1-fill: #000;
  --avatar-container-default1-border: 0 solid rgba(0,0,0,0);
  --avatar-container-focus-sizing: 288px;
  --avatar-container-focus-fill: #0064FF;
  --avatar-container-focus-border: 0 solid #000;
  --avatar-title-fill: #fff;
  --avatar-icon-height: 152px;
  --avatar-icon-width: 152px;
  --avatar-icon-fill: #fff;
  --avatar-icon-default-sizing: 152px;
  --avatar-icon-default-fill: #fff;
  --avatar-icon-focus-sizing: 152px;
  --avatar-icon-focus-fill: #fff;
  --avatar-highlighted-color: #0064FF;
  --avatar-highlighted-width: 8px;
  --avatar-highlighted-style: solid;
  --avatar-item-spacing: 32px;
  --avatar-avatar-spacing: 80px;
  --avatar-default1: 0 solid rgba(0,0,0,0); /*   */
  --avatar-focus: 0 solid #000;
  --avatar-typography-default-fill: #fff;
  --avatar-typography-default-typography: 500 40px/1.3 Outfit;
  --avatar-typography-focus-fill: #fff;
  --avatar-typography-focus-typography: 500 40px/1.3 Outfit;
  --player-width: 1920px;
  --player-height: 1080px;
  --player-icon-play-fill: #fff;
  --player-icon-play-sizing: 140px;
  --player-icon-forward-rewind-fill: #fff;
  --player-icon-forward-rewind-sizing: 100px;
  --player-icon-subtitle-fill: #fff;
  --player-icon-subtitle-sizing: 60px;
  --zero: 0;
  --light: 24;
  --medium: 48;
  --intense: 80;
  --full: 100;
  --capitalize: capitalize; /* With Value "Capitalize", The First Letter In Each Word Are Capitalized. This Is Good For Titles And Subtitles. */
  --uppercase: uppercase; /* WITH VALUE "UPPER", ALL THE LETTERS ARE UPPERCASE. THIS IS GOOD FOR GRABBING ATTENTION. */
  --lowercase: lowercase; /* with value "lower", all the letters are lowercase. this is good for poetry perhaps. */
  --none: none;
  --metadata-row-text-fill: #fff;
  --metadata-row-bullet-height: 4px;
  --metadata-row-bullet-width: 4px;
  --metadata-row-bullet-fill: #fff;
  --cards-item-highlighted-color: #fff;
  --cards-item-highlighted-width: 4px;
  --cards-item-highlighted-style: solid;
  --color-global-blue1: #8AB8FF;
  --color-global-blue2: #3B82F6;
  --color-global-blue3: #1D4ED8;
  --color-global-blue4: #002866;
  --color-global-tangerine1: #FFB45D;
  --color-global-tangerine2: #FEA134;
  --color-global-tangerine3: #FE7235;
  --color-global-tangerine4: #C84D18;
  --color-global-white: #fff;
  --color-global-grey1: #E2E8F0;
  --color-global-grey2: rgba(255,255,255,0.4);
  --color-global-grey3: #64748B;
  --color-global-grey4: #475569;
  --color-global-grey5: #334155;
  --color-global-grey6: #1E293B;
  --color-global-grey7: #0F172A;
  --color-global-black: #000;
  --color-global-red: #FF2B62;
  --color-global-green: #04BB42;
  --color-global-yellow: #FFB71E;
  --color-global-light-blue: #00A3FF;
  --color-primary-default: #0064FF;
  --color-primary-brand-emphasis: #1D4ED8;
  --color-primary-brand-emphasis-2: #002866;
  --color-primary-brand-subtle: #8AB8FF;
  --color-secondary-brand-default: #FEA134;
  --color-secondary-brand-emphasis: #FE7235;
  --color-secondary-brand-emphasis-2: #C84D18;
  --color-secondary-brand-subtle: #FFB45D;
  --color-greyscale-grey1: #E2E8F0;
  --color-greyscale-grey2: rgba(255,255,255,0.4);
  --color-greyscale-grey3: #64748B;
  --color-greyscale-grey4: #475569;
  --color-greyscale-grey5: #334155;
  --color-greyscale-grey6: rgba(255,255,255,0.2);
  --color-greyscale-grey7: #0F172A;
  --color-base-white: #fff;
  --color-base-black: #000;
  --color-system-error: #f00;
  --color-system-success: #00ff58;
  --color-system-alert: #FFB71E;
  --color-system-info: #00A3FF;
  --color-button-primary-container-default: #334155;
  --color-button-primary-container-emphasis: #0064FF;
  --color-button-primary-container-inactive: rgba(51,65,85,0.6);
  --color-button-primary-label-default: #fff;
  --color-button-primary-label-focus: #fff;
  --color-button-primary-label-disabled: rgba(253,253,253,0.8);
  --color-button-primary-icon-default: #fff;
  --color-button-primary-icon-focus: #fff;
  --color-button-primary-icon-disabled: rgba(253,253,253,0.8);
  --color-button-secondary-container-default: #002eff;
  --color-button-secondary-container-focus: #0064FF;
  --color-button-secondary-container-inactive: #002eff;
  --color-button-secondary-label-default: #fff;
  --color-button-secondary-label-focus: #fff;
  --color-button-secondary-label-inactive: #fff;
  --color-button-secondary-icon-default: #fff;
  --color-button-secondary-icon-focus: #fff;
  --color-button-secondary-icon-inactive: #fff;
  --color-button-secondary-border-default: #3B82F6;
  --color-button-secondary-border-focus: #002866;
  --color-button-secondary-border-inactive: #8AB8FF;
  --color-input-field-outline-default: #64748B;
  --color-input-field-outline-focused: #0064FF;
  --color-input-field-outline-typing: #0064FF;
  --color-input-field-outline-entered: #0064FF;
  --color-input-field-outline-error: #f00;
  --color-input-field-outline-success: #00ff58;
  --color-input-field-outline-inactive: rgba(255,255,255,0.4);
  --color-input-field-container-inactive: #475569;
  --color-avatar-highlighted-outline: #0064FF;
  --color-card-item-highlighted-outline: #fff;
  --color-text-light: #fff;
  --color-text-medium: rgb(255,255,255,0.2);
  --progress-bar-card-height: 12px;
  --progress-bar-player-width: 1140px;
  --progress-bar-player-height: 12px;
  --progress-bar-color-background-fill: rgba(255,255,255,0.2);
  --progress-bar-color-progress-fill: #0064FF;
  --card-16by9-large-default-height: 380px;
  --card-16by9-large-default-width: 573px;
  --card-16by9-large-default-border-radius: 10px;
  --card-16by9-large-default-box-shadow: 0 0 0 0 #000000;
  --card-16by9-large-focus-height: 418px;
  --card-16by9-large-focus-width: 630.3px;
  --card-16by9-large-focus-border-radius: 10px;
  --card-16by9-large-focus-box-shadow: 4px 4px 20px 20px rgba(0,0,0,0.2);
  --card-16by9-large-height: 380px;
  --card-16by9-large-width: 573px;
  --card-16by9-medium-height: 361px;
  --card-16by9-medium-width: 546px;
  --card-16by9-medium-default-height: 361px;
  --card-16by9-medium-default-width: 546px;
  --card-16by9-medium-default-border-radius: 10px;
  --card-16by9-medium-default-box-shadow: 0 0 0 0 #000000;
  --card-16by9-medium-focus-height: 397.1px;
  --card-16by9-medium-focus-width: 600.6px;
  --card-16by9-medium-focus-border-radius: 10px;
  --card-16by9-medium-focus-box-shadow: 4px 4px 20px 20px rgba(0,0,0,0.2);
  --card-9by16-default-height: 584px;
  --card-9by16-default-width: 425px;
  --card-9by16-default-border-radius: 10px;
  --card-9by16-default-box-shadow: 0 0 0 0 #000000;
  --card-9by16-focus-height: 642.4px;
  --card-9by16-focus-width: 467.5px;
  --card-9by16-focus-border-radius: 10px;
  --card-9by16-focus-box-shadow: 4px 4px 20px 20px rgba(0,0,0,0.2);
  --card-9by16-height: 584px;
  --card-9by16-width: 425px;
  --card-focus: 4px 4px 20px 20px rgba(0,0,0,0.2);
  --no-shadow: 0 0 0 0 #000000;
  --metadata-text-typography: 700 24px/1.3 Outfit;
  --metadata-text-fill: #fff;
  --metadata-bullet-sizing: 4px;
  --metadata-bullet-fill: #fff;
  --navigation-menu-fill: #000;
  --navigation-menu-border-radius: 0;
  --navigation-menu-item-spacing: 40px;
  --navigation-menu-item-collapsed-container-default-fill: rgba(0,0,0,0);
  --navigation-menu-item-collapsed-container-default-border-width: 0;
  --navigation-menu-item-collapsed-container-default-vertical-padding: 16px;
  --navigation-menu-item-collapsed-container-default-horizontal-padding: 24px;
  --navigation-menu-item-collapsed-container-selected-fill: rgba(0,0,0,0);
  --navigation-menu-item-collapsed-container-selected-border-width: 0;
  --navigation-menu-item-collapsed-container-selected-vertical-padding: 16px;
  --navigation-menu-item-collapsed-container-selected-horizontal-padding: 24px;
  --navigation-menu-item-collapsed-container-all-border-radius: 1000px;
  --navigation-menu-item-collapsed-icon-default-sizing: 40px;
  --navigation-menu-item-collapsed-icon-default-fill: rgba(253,253,253,0.6);
  --navigation-menu-item-collapsed-icon-selected-sizing: 40px;
  --navigation-menu-item-collapsed-icon-selected-fill: rgba(253,253,253,0.6);
  --navigation-menu-item-collapsed-underline-default-fill: rgba(0,0,0,0);
  --navigation-menu-item-collapsed-underline-default-height: 0;
  --navigation-menu-item-collapsed-underline-default-width: 0;
  --navigation-menu-item-collapsed-underline-selected-fill: #0064FF;
  --navigation-menu-item-collapsed-underline-selected-height: 4px;
  --navigation-menu-item-collapsed-underline-selected-width: 28px;
  --navigation-menu-item-expanded-typography-default-typography: 500 26px/1.1 Outfit;
  --navigation-menu-item-expanded-typography-default-fill: rgb(255,255,255,0.2);
  --navigation-menu-item-expanded-typography-focus-typography: 500 26px/1.1 Outfit;
  --navigation-menu-item-expanded-typography-focus-fill: #fff;
  --navigation-menu-item-expanded-typography-selected-typography: 500 26px/1.1 Outfit;
  --navigation-menu-item-expanded-typography-selected-fill: #fff;
  --navigation-menu-item-expanded-container-default-fill: rgba(0,0,0,0);
  --navigation-menu-item-expanded-container-default-border-width: 0;
  --navigation-menu-item-expanded-container-default-vertical-padding: 16px;
  --navigation-menu-item-expanded-container-default-horizontal-padding: 24px;
  --navigation-menu-item-expanded-container-focus-fill: #0064FF;
  --navigation-menu-item-expanded-container-focus-border-width: 0;
  --navigation-menu-item-expanded-container-focus-vertical-padding: 16px;
  --navigation-menu-item-expanded-container-focus-horizontal-padding: 24px;
  --navigation-menu-item-expanded-container-selected-fill: rgba(51,65,85,0.6);
  --navigation-menu-item-expanded-container-selected-border-width: 0;
  --navigation-menu-item-expanded-container-selected-vertical-padding: 16px;
  --navigation-menu-item-expanded-container-selected-horizontal-padding: 24px;
  --navigation-menu-item-expanded-container-all-border-radius: 1000px;
  --navigation-menu-item-expanded-icon-default-sizing: 40px;
  --navigation-menu-item-expanded-icon-default-fill: rgb(255,255,255,0.2);
  --navigation-menu-item-expanded-icon-focus-sizing: 40px;
  --navigation-menu-item-expanded-icon-focus-fill: #fff;
  --navigation-menu-item-expanded-icon-selected-sizing: 40px;
  --navigation-menu-item-expanded-icon-selected-fill: #fff;
}
