@mixin r720p {

  @media (max-width: 1281px) {
    @content;
  }
}

@mixin r1080p {

  @media (min-width: 1282px) {
    @content;
  }
}

@function from720pto1080p($val) {

  @return $val * 1.5;
}

@function from1080pto720p($val) {

  @return $val * .6666;
}

@mixin browser($devices) {

  @each $device in $devices {
    html[data-device*='#{$device}'] & {
      @content;
    }
  }
}

@function strip-unit($number) {
  @if type-of($number) == 'number' and not unitless($number) {

    @return $number / ($number * 0 + 1);
  }

  @return $number;
}
