@import '~#/theme/main';

.card-height {
  height: 100%;
}

.card-image-wrapper {
  height: 100%;
  position: relative;
}

.background {
  background-size: cover !important;
  border-radius: 8px;
  padding: 0;
  position: relative;
  width: 100%;
}

.game-details-wrapper {
  backdrop-filter: blur(8px);
  border-radius: 0 0 8px 8px;
  bottom: 0;
  height: 80px;
  overflow: hidden;
  position: absolute;
  width: 100%;
}

.overlay {
  background-color: #ffffff96;
  height: 100%;
  opacity: .6;
  position: absolute;
  width: 100%;
  z-index: 1;
}

.live-dot {
  background-color: rgba(255, 43, 98, 1);
  border-radius: 50%;
  height: 10px;
  width: 10px;
}

.game-details {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  height: 100%;
  justify-content: space-between;
  padding: vw(12px);
  position: relative;
  z-index: 2;

  > div {
    align-items: center;
    border-radius: 8px;
    display: flex;
    gap: vw(8px);
    padding: vw(8px);
  }

  p {
    color: #fff;
    font-size: vw(28px);
    margin: 0;
  }
}

.home-team {
  p.team-name {
    text-align: start;
  }
}

.away-team {
  p.team-name {
    text-align: end;
  }
}

.team-logo {
  height: vw(40px);
  width: vw(40px);

  img {
    max-width: 100%;
  }
}

p.team-name {
  font-size: vw(22px);
  font-weight: 400;
  width: vw(60px);
}

p.score {
  font-size: vw(32px);
  font-weight: 700;
}
