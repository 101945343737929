@import '~#/theme/main';

$details-text-color: $rgb-235;

.wrapper {
  border-radius: $card-corner-radius-xl;
  box-shadow: none;
  overflow: hidden;
  transition: all 300ms ease-in-out;
  transition-delay: 100ms;

  &.wrapper-focused {
    border: $card-content-stroke-weight solid $card-content-border-focus;
    border-radius: vw(8px);
    box-shadow:
      $card-content-effects-focus-x-axis $card-content-effects-focus-y-axis
      $card-content-effects-focus-blur $card-content-effects-focus-spread $card-content-effects-focus-drop-shadow;
    transition: all 300ms ease-in-out;

    &.rounded-focus {
      border-radius: 50%;
    }
  }
}

.team-logo-container {
  background-repeat: no-repeat;
  background-size: contain;
  display: block;
  height: vw(155px);
  left: 50%;
  position: absolute;
  top: vw(25px);
  transform: translateX(-50%);
  width: vw(155px);
}

.team-color-bar {
  border-bottom-left-radius: vw(5px);
  border-bottom-right-radius: vw(5px);
  border-top: solid vw(2px) #5c5c5c;
  bottom: 0;
  box-sizing: border-box;
  height: vw(25px);
  position: absolute;
  width: 100%;
  z-index: 1;
}

// MLB Card Styles
.mlb-scores {
  font-size: vw(40px);
  height: vw(59px);
  line-height: vw(47px);
  position: absolute;
  text-align: center;
  top: vw(45px);
  width: 100%;

  &.time {
    font-size: vw(16px);
  }
}

.mlb-status-container {
  align-items: center;
  background-color: #373737;
  color: #fdbe11;
  font-size: vw(16px);
  font-weight: 400;
  height: vw(27px);
  line-height: vw(27px);
  text-align: center;
  width: 100%;
}

.mlb-container {
  display: flex;
  flex-direction: row;

  .mlb-home-container {
    height: vw(147px);
    width: 50%;
  }

  .mlb-away-container {
    height: vw(147px);
    width: 50%;
  }
}

.mlb-home-city {
  box-sizing: border-box;
  font-size: vw(14px);
  height: vw(17px);
  line-height: vw(17px);
  padding: 0 vw(16px);
  width: 100%;
}

.mlb-home-name {
  box-sizing: border-box;
  font-size: vw(24px);
  height: vw(28px);
  line-height: vw(28px);
  padding: 0 vw(16px);
  width: 100%;
}

.mlb-home-stats {
  border: vw(1px) solid rgba(255, 255, 255, .24);
  border-radius: vw(8px);
  box-sizing: border-box;
  font-size: vw(14px);
  height: vw(17px);
  line-height: vw(17px);
  margin-left: vw(16px);
  margin-top: vw(8px);
  padding: 0 vw(16px);
  text-align: center;
  width: vw(90px);
}

.mlb-away-city {
  box-sizing: border-box;
  font-size: vw(14px);
  height: vw(17px);
  line-height: vw(17px);
  padding: 0 vw(16px);
  text-align: right;
  width: 100%;
}

.mlb-away-name {
  box-sizing: border-box;
  font-size: vw(24px);
  height: vw(28px);
  line-height: vw(28px);
  padding: 0 vw(16px);
  text-align: right;
  width: 100%;
}

.mlb-away-stats {
  align-items: end;
  border: vw(1px) solid rgba(255, 255, 255, .24);
  border-radius: vw(8px);
  box-sizing: border-box;
  float: right;
  font-size: vw(14px);
  height: vw(17px);
  line-height: vw(17px);
  margin-right: vw(16px);
  margin-top: vw(8px);
  text-align: center;
  width: vw(90px);
}

.mlb-footer-container {
  align-items: center;
  bottom: 0;
  font-size: vw(14px);
  font-weight: 400;
  height: vw(48px);
  line-height: vw(21px);
  position: absolute;
  text-align: center;
  vertical-align: middle;
  width: 100%;

  .span-block {
    display: block;
  }

  .stadium-name {
    font-weight: bolder;
  }
}

.mlb-logo-container {
  box-sizing: border-box;
  height: vw(59px);
  padding-top: vw(8px);
  width: 100%;

  .mlb-home-logo {
    background-repeat: no-repeat;
    background-size: contain;
    float: left;
    height: vw(40px);
    margin-left: vw(16px);
    width: vw(40px);
  }

  .mlb-away-logo {
    background-repeat: no-repeat;
    background-size: contain;
    float: right;
    height: vw(40px);
    margin-right: vw(16px);
    width: vw(40px);
  }
}

.mlb-team-name {
  margin-top: vw(9px);

  .mlb-name {
    font-size: vw(32px);
    font-weight: bolder;
    line-height: (38px);
  }
}

.player-background-mlb {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: vw(400px);
  position: absolute;
  width: 100%;
}

.overlay {
  height: 100%;
  width: 100%;
}

.seek-bar-container {
  bottom: 0;
  left: 0;
  position: absolute;
  width: 100%;
}

.slider {
  color: var(--progress-bar-color-progress-fill) !important;
  height: var(--progress-bar-card-height) !important;
  margin-left: 0;
  margin-right: 0;
  padding: 0 !important;

  > span {
    &:first-of-type {
      opacity: 1;
    }

    &:last-of-type {
      display: none;
    }
  }
}
