@import '~#/theme/main';

.item {
  font-size: vw(22px);
  line-height: vw(48px);
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;

  &::after {
    content: ' ';
    display: block;
    margin-bottom: vw($side-navigation-spacing-item-item-inner);
    position: relative;
  }

  &:first-child::after {
    margin-bottom: calc(vw(calc($side-navigation-spacing-item-item-outer + 20px)));
  }

  &:nth-last-child(2)::after {
    margin-bottom: vw($side-navigation-spacing-item-item-outer);
  }

  &:nth-child(n+2):nth-last-child(n+3)::after {
    margin-bottom: vw($side-navigation-spacing-navigation-item);
  }

  @include browser($legacy-devices) {
    width: vw(80px);
  }

  button {
    align-items: center;
    border-width: 0;
    color: var(--button-primary-typography-default-fill);
    cursor: pointer;
    display: flex;
    width: 100%;

    svg {
      position: relative;
    }
  }

  img {
    border-radius: 50%;
    height: vw(69px);
    object-fit: cover;
    width: vw(69px);
  }

  &.collapsed {
    button {
      background-color: $side-navigation-navigation-item-collapsed-fill-rest;
      border-radius: $side-navigation-corner-radius;
      flex-direction: column;
      padding: $side-navigation-padding-navigation-item-vertical $side-navigation-padding-navigation-item-horizontal;

      div {
        position: absolute;
        text-align: center;
        width: vw(40px);

        span {
          background-color: $side-navigation-navigation-item-collapsed-border-active;
          display: inline-block;
          position: relative;
        }
      }

      svg {
        height: vw(40px);
        width: vw(40px);

        path {
          fill: $side-navigation-navigation-item-collapsed-icon-rest;
        }
      }

      &.is-active {
        background-color: $side-navigation-navigation-item-collapsed-fill-active;
        border: 0 solid $side-navigation-navigation-item-collapsed-border-active;
        padding: $side-navigation-padding-navigation-item-vertical $side-navigation-padding-navigation-item-horizontal;

        div {
          height: 4px;
          margin-top: 12px;
          width: vw(40px);

          span {
            background-color: $side-navigation-navigation-item-collapsed-border-active;
            height: 3px;
            position: relative;
            top: calc($side-navigation-padding-navigation-item-vertical + 4px);
            width: 28px;
          }
        }

        svg {
          height: vw(40px);
          width: vw(40px);

          path {
            fill: $side-navigation-navigation-item-collapsed-icon-active;
          }
        }
      }
    }
  }

  &.expanded {
    button {
      background-color: $side-navigation-navigation-item-expanded-fill-rest;
      border: 0 solid $side-navigation-navigation-item-expanded-border-rest;
      border-radius: 6px;
      color: $side-navigation-navigation-item-expanded-text-rest;
      font-family: Outfit, Helvetica;
      font-size: 24px;
      font-weight: 400;
      min-height: $side-navigation-sizing-navigation-item-container-expanded-min-height;
      min-width: $side-navigation-sizing-navigation-item-container-expanded-min-width;
      padding: $side-navigation-padding-navigation-item-vertical $side-navigation-padding-navigation-item-horizontal;

      svg {
        height: vw(40px);
        margin-right: vw(16px);
        width: vw(40px);

        path {
          fill: $side-navigation-navigation-item-expanded-icon-rest;
        }
      }

      &.is-active {
        background-color: $side-navigation-navigation-item-expanded-fill-active;
        border: 0 solid $side-navigation-navigation-item-expanded-border-active;
        border-radius: 6px;
        color: $side-navigation-navigation-item-expanded-text-active;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 400;
        min-height: $side-navigation-sizing-navigation-item-container-expanded-min-height;
        min-width: $side-navigation-sizing-navigation-item-container-expanded-min-width;
        padding: $side-navigation-padding-navigation-item-vertical $side-navigation-padding-navigation-item-horizontal;

        div {
          background-color: $side-navigation-navigation-item-collapsed-border-active;
          height: 3px;
          left: 11px;
          position: absolute;
          top: 37px;
          width: 32px;
        }

        svg {
          height: vw(40px);
          margin-right: vw(16px);
          width: vw(40px);

          path {
            fill: $side-navigation-navigation-item-expanded-icon-active;
          }
        }
      }
    }

    &.item-focused {
      button {
        background-color: $side-navigation-navigation-item-expanded-fill-focus;
        border: 0 solid $side-navigation-navigation-item-expanded-border-focus;
        border-radius: 6px;
        color: $side-navigation-navigation-item-expanded-text-focus;
        font-family: Outfit;
        font-size: 24px;
        font-weight: 400;
        min-height: $side-navigation-sizing-navigation-item-container-expanded-min-height;
        min-width: $side-navigation-sizing-navigation-item-container-expanded-min-width;
        padding: $side-navigation-padding-navigation-item-vertical $side-navigation-padding-navigation-item-horizontal;

        svg {
          height: vw(40px);
          margin-right: vw(16px);
          width: vw(40px);

          path {
            fill: $side-navigation-navigation-item-expanded-icon-focus;
          }
        }
      }
    }
  }
}

.secondary {
  margin-left: vw(35px);

  @include browser($legacy-devices) {
    margin-left: vw(17.5px) !important;
    margin-right: vw(17.5px) !important;
  }

  svg {
    path {
      fill: var(--coloronbackground);
    }
  }
}

.item-focused {
  // svg {
  //   path {
  //     fill: var(--accent);
  //   }
  // }

  .profile-picture {
    animation: highlight .3s forwards;
    border: vw(4px) solid var(--accent);
    opacity: 1;
  }
}

.icon {
  height: vw(45px);
}
