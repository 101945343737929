@import '~#/theme/vw';

.grid {
  // items outside of grid area will not be displayed. change css size of grid if items are not displayed.
  overflow: visible;
  position: relative;
}

.tail-block,
.head-block {
  opacity: 1;
  position: absolute;
}

.grid.vertical {
  margin-left: vw(90px);
  margin-top: vw(10px);

  .tail-block,
  .head-block {
    left: 0;
  }

  .tail-block {
    bottom: 0;
  }

  .head-block {
    top: 0;
  }
}

.grid.horizontal {
  .tail-block,
  .head-block {
    top: 0;
  }

  .tail-block {
    right: 0;
  }

  .head-block {
    left: 0;
  }

  &[dir='rtl'] {
    .tail-block {
      left: 0;
      right: auto;
    }

    .head-block {
      left: auto;
      right: 0;
    }
  }
}

.grid.head .head-block,
.grid.tail .tail-block {
  opacity: 0;
  pointer-events: none;
}

.animated-grid .shift-panel {
  transition: transform .3s ease-out 0s;
}

.navigation-arrow {
  &.left,
  &.right {
    & > .arrow-button {
      left: vw(60px);
      right: auto;
    }
  }

  &.right > .arrow-button {
    left: auto;
    right: vw(60px);
  }
}

.hero-dots {
  bottom: vw(171px);
  position: absolute;
  text-align: center;
  width: 100%;

  div {
    background-color: #fff;
    border-radius: 50%;
    display: inline-block;
    height: vw(20px);
    margin: 0 vw(12px);
    width: vw(20px);

    &.active {
      background-color: #0064ff;
    }
  }
}
