@import '~#/theme/main';

.card-height {
  height: vw(356px);
}

.background {
  align-items: center;
  background-size: cover;
  border-radius: 50%;
  box-shadow: var(--card-9by16-default-box-shadow);
  display: flex;
  justify-content: center;
  padding: 0;
  position: relative;
  width: 100%;

  img {
    height: 80%;
    max-width: 80%;
    object-fit: scale-down;
  }
}

.team-name {
  font-size: vw(28px);
  font-weight: 300;
  line-height: vw(34px);
  margin-top: vw(24px);
  text-align: center;
}
