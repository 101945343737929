@import '~#/theme/main';

@import '~#/theme/colors';

$details-text-color: $rgb-235;

.button {
  button {
    background-color: $rgb-30;
    border: 0;
    border-radius: vw(16px);
    color: rgba(242, 243, 244, .8);
    font-size: vw(24px);
    font-weight: 400;
    height: vw(88px);
    width: vw(475px);
  }

  &.focused {
    background-color: #0064ff;
    border-radius: vw(6px);
    box-shadow: 0 30px 30px 0 rgba(0, 0, 0, .5);
    color: #f2f3f4;
  }
}

.icon {
  height: vw(45px);
  position: absolute;
  right: vw(20px);
  top: vw(34px);
  width: vw(55px);
}
