/* Slider */
.slick-slider {
  box-sizing: border-box;
  display: block;
  position: relative;
  -webkit-tap-highlight-color: transparent;
  touch-action: pan-y;
  -webkit-touch-callout: none;
  user-select: none;
}

.slick-list {
  display: block;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: relative;
}

.slick-list:focus {
  outline: none;
}

.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-track {
  display: block;
  left: 0;
  position: relative;
  top: 0;
}

.slick-track::before,
.slick-track::after {
  content: '';
  display: table;
}

.slick-track::after {
  clear: both;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  transform: translate3d(0, 0, 0);
}

.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;
  height: 100%;
  min-height: 1px;
}

.slick-slide.slick-loading img {
  display: none;
}

.slick-slide.dragging img {
  pointer-events: none;
}

.slick-initialized .slick-slide {
  display: block;
}

.slick-loading .slick-slide {
  visibility: hidden;
}

.slick-vertical .slick-slide {
  border: 1px solid transparent;
  display: block;
  height: auto;
}

.slick-arrow.slick-hidden {
  display: none;
}

/* Dots */
.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  display: flex !important;
  gap: 24px;
  justify-content: center;
  list-style: none;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: center;
  top: -100px;
  width: 100%;
}

.slick-dots li {
  background-color: #808080;
  border-radius: 50%;
  cursor: pointer;
  display: inline-block;
  height: 20px;
  margin: 0 5px;
  padding: 0;
  position: relative;
  width: 20px;
}

.slick-dots li.slick-active {
  background-color: blue !important;
}

.slick-dots li button {
  display: none;
}
