@mixin default-button {
  background: transparent;
  border-radius: 0;
}

@function solid-border($color: transparent, $width: $border-width) {

  @return #{$color} #{$width} solid;
}

@mixin button-all {
  font-family: Outfit, sans-serif;
  font-size: vw(24px);
  font-weight: 500;
  line-height: vw(30px);
  min-height: $button-sizing-min-height-md;
  min-width: $button-sizing-min-width-md;
  padding:
    vw($button-padding-vertical-md)
    vw($button-padding-horizontal-md);
  padding: $button-padding-vertical-md $button-padding-horizontal-md;

  & svg {
    margin-right: 0;

    &.reverse {
      margin-left: 0;
    }
  }
}

// Primary button token styling

@mixin button-primary {
  background-color: $button-primary-fill-rest;
  border-radius: $button-corner-radius-md;
  border-width: $button-primary-border-rest;
  color: $button-primary-text-rest;

  & svg {
    path {
      fill: $button-primary-icon-rest !important;
    }
  }
}

@mixin button-reverse {
  flex-direction: row-reverse;
}

@mixin button-primary-focus {
  background-color: $button-primary-fill-focus;
  border-radius: $button-corner-radius-md;
  border-width: $button-primary-border-focus;
  color: $button-primary-text-focus;

  & svg {
    path {
      fill: $button-primary-icon-focus !important;
    }
  }
}

@mixin button-primary-disabled {
  background-color: $button-primary-fill-disabled;
  border-radius: $button-corner-radius-md;
  border-width: $button-primary-border-disabled;
  color: $button-primary-fill-disabled;

  & svg {
    height: vw($button-sizing-icon-md);
    width: vw($button-sizing-icon-md);

    path {
      fill: $button-primary-text-disabled !important;
    }
  }
}

@mixin button-secondary {
  background-color: $button-secondary-fill-rest;
  border-radius: $button-corner-radius-md;
  border-width: $button-secondary-border-rest;
  color: $button-secondary-fill-focus;

  & svg {
    path {
      fill: $button-secondary-icon-rest !important;
    }
  }
}

@mixin button-secondary-focus {
  background-color: $button-secondary-fill-focus;
  border-radius: $button-corner-radius-md;
  border-width: $button-secondary-border-focus;
  color: $button-secondary-fill-focus;

  & svg {
    path {
      fill: $button-secondary-icon-focus !important;
    }
  }
}

@mixin button-secondary-disabled {
  background-color: $button-secondary-fill-disabled;
  border-radius: $button-corner-radius-md;
  border-width: $button-secondary-border-disabled;
  color: $button-primary-fill-disabled;

  & svg {
    height: vw($button-sizing-icon-md);
    width: vw($button-sizing-icon-md);

    path {
      fill: $button-primary-text-disabled !important;
    }
  }
}
