@import '~#/theme/main';

.card-height {
  height: vw(322px);
}

.card-image-wrapper {
  box-shadow: none;
  position: relative;
  transition: all 300ms ease-in-out;

  &.focused {
    border-radius: vw(8px);
    box-shadow: 0 0 100px 0 rgba(0, 100, 255, 1), 0 0 0 4px rgba(0, 100, 255, 1);
    transition: all 300ms ease-in-out;
  }
}

.inner {
  background-color: $color-primary-default;
  border-bottom-left-radius: 8px;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.background {
  background-size: cover !important;
  border-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0;
  position: relative;
  width: 100%;

  @include browser($legacy-devices) {
    border-radius: 12px !important;
  }
}

.date-item {
  background: #eee;
  border-radius: vw(6px);
  box-shadow: 0 6px 45px 0 rgba(0, 0, 0, .5);
  font-size: vw(20px);
  font-weight: 700;
  position: absolute;
  right: vw(16px);
  top: vw(16px);

  p {
    color: #000;
    margin: 0;
    padding: vw(8px) vw(12px);
  }
}
