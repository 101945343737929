@import '~#/theme/main';

.container-title {
  color: $color-light-full;
  font-size: vw(36px);
  font-weight: 300;
  margin-bottom: vw(28px);
}

.outer-container {
  margin-left: vw(30px);
  margin-top: vw(80px);
}

.inner-container {
  width: 100%;

  // &.ltr {
  //   padding: vw(12px) 0 vw(40px) vw(75px);
  // }

  // &.rtl {
  //   padding: vw(12px) vw(75px) vw(40px) 0;
  // }
}

.hero-vertical,
.elevate-hero-banner {
  &.outer-container {
    margin: auto;
    margin-bottom: -16vh;
    padding-bottom: 54%; // aspect ratio 16x6
    padding-top: 0;
    position: relative;
    width: 100%;
    z-index: -1; // allow other containers to be on top of the hero
  }

  .container-title {
    display: none;
  }

  .inner-container {
    bottom: 0;
    display: block;
    left: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: 0;
  }
}
