@import '~#/theme/main';

.page {
  background-color: #000;
  margin-left: vw(213px);
  overflow: hidden;

  > div {
    width: auto !important;
  }
}
