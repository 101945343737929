@import '~#/theme/main';

.wrapper {
  background-color: $rgb-60;
  border: vw(9px) solid transparent;
  box-sizing: border-box;

  &.focused {
    border: solid vw(9px) var(--accent);
  }

  .title {
    color: var(--coloronbackground);
    font-size: vw(30px);
    position: relative;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
  }
}
