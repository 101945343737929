@import '~#/theme/main';

$title-size: vw(35px);

.logo-title {
  display: inline-block;
  flex-grow: 1;
  font-size: $title-size;
  height: $title-size;
  line-height: vw(102px);
  padding-left: vw(20px);
  position: absolute;
  top: 0;
  vertical-align: middle;
  white-space: nowrap;
}

.logo-title-local {
  display: table-cell;
  font-size: $title-size;
  padding-left: vw(20px);
  vertical-align: middle;
  white-space: nowrap;
}

.logo {
  height: vw($side-navigation-sizing-app-logo-min-height);
  max-width: vw($side-navigation-sizing-app-logo-min-width);
  position: absolute;
  transition: width 250ms ease;
}

.logo-link {
  height: vw(50px);
  margin-bottom: auto;
  margin-top: auto;
}

.user-control {

  @include responsive(medium-screens) {
    right: vw(96px);
    top: vw(26px);
  }

  position: absolute;
  right: vw(40px);
}

.container {

  @include display-table;

  align-items: center;
  background-color: #000;
  border-radius: $side-navigation-corner-radius;
  box-sizing: border-box;
  color: var(--coloronbackground);
  display: flex;
  flex-direction: column;
  height: 100%;
  left: 100px;
  padding-left: vw(20px) !important;
  position: fixed;
  transition: width 250ms ease-out;
  visibility: hidden;
  width: vw($side-navigation-sizing-navigation-item-container-collapsed-min-width);
  z-index: 2;

  .img {
    &.ltr {
      float: left;
      padding-left: 8px;
    }

    &.rtl {
      float: right;
    }
  }

  &.is-visible {
    visibility: visible;
  }

  &.is-open {
    min-width: vw(210px);
    transition: width 250ms ease-in;
    visibility: visible;

    .logo {
      transition: width 250ms ease;
      width: vw(180px);
    }
  }

  &.fullscreen {
    position: absolute;
  }

  button {

    @include default-button;

    @include subtitle-font-size;

    font-family: var(--typo-tv-heading-1-font-family);
    font-size: var(--typo-tv-button-default-font-size);
    font-weight: var(--typo-tv-button-default-font-weight);
    letter-spacing: var(--typo-tv-button-default-letter-spacing);
    line-height: var(--typo-tv-button-default-line-height);
    position: relative;
    text-align: center;
  }

  nav {
    position: relative;
    top: calc($side-navigation-sizing-app-logo-min-height + $side-navigation-spacing-item-item-inner);
  }

  // stylelint-disable-next-line selector-class-pattern
  :global(.triState) button {
    &::before {

      @include pseudo;

      border-bottom: solid-border();
      height: 70%; // Tune this to match the design
      width: size-without-border(10px);
    }

    &.button-focused::before {
      border-bottom: solid-border(var(--accent));
    }
  }
}

.wrapper {
  margin-top: 145px;
}

.logo-collapsed {
  display: flex !important;
}

.overlay {
  background-color: rgba(0, 0, 0, .4);
  height: 100vh;
  left: 0;
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 1;
}

.button-focused {
  color: var(--accent);

  img {
    border: solid-border(var(--accent));
  }
}
