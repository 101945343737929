@import '~#/theme/main';

.card-height {
  height: vw(410px);
}

.background {
  background-size: cover;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  padding: 0;
  position: relative;
  width: 100%;

  @include browser($legacy-devices) {
    border-radius: 12px !important;
  }
}

.player-info {
  background-color: #1e1d21;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  padding: vw(32px) vw(24px) vw(16px);
  position: relative;

  p {
    &:first-of-type {
      font-size: vw(32px);
      font-weight: 500;
      line-height: vw(42px);
      margin-bottom: vw(8px);
    }

    &:last-of-type {
      font-size: vw(28px);
      font-weight: 300;
      line-height: vw(35px);
    }

    margin: 0;
  }
}

.player-team-logo {
  align-items: center;
  background-color: #1e1d21;
  border-radius: 50%;
  display: flex;
  height: vw(150px);
  justify-content: center;
  position: absolute;
  right: vw(20px);
  top: calc(-50% + 24px);
  width: vw(150px);

  > div {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: vw(110px);
    justify-content: center;
    width: vw(110px);
  }

  img {
    max-height: vw(110px);
    max-width: vw(110px);
  }
}
