@import '~#/theme/main';

.bg-gradient {
  height: 100%;
  position: absolute;
  top: 0;

  div {
    height: 100%;
  }
}

.metadata {
  align-items: center;
  background-color: $tag-rectangular-fill;
  border: $tag-rectangular-stroke-weight solid $tag-rectangular-border;
  border-radius: $tag-corner-radius-sm;
  display: flex;
  height: 46px;
  justify-content: space-evenly;
  min-height: 46px;
  min-width: 204px;
  padding: $metadata-spacing-score-padding-vertical-sm $metadata-spacing-score-padding-horizontal-sm;
}

.metadata span {
  font-size: 24px;
}

.team-logo {
  height: 40px !important;
  width: 40px !important;
}

.content-container {

  @include bottom-align-item;

  box-sizing: border-box;
  height: 100%;
  padding: vw(270px) 0 vw(171px) vw(20px);
  width: 100%;
}

.tag {
  font-size: vw(31px);
  font-weight: 700;
  line-height: vw(36px);
  position: absolute;
  top: vw(186px);

  .live {
    background-color: #c70726;
    border-radius: 50%;
    display: inline-block;
    height: vw(15px);
    margin-right: vw(10px);
    width: vw(15px);
  }
}

.title {
  color: $font-color;
  font-size: $title-large-font-size;
  font-weight: 500;
  height: auto;
  line-height: vw(60px);
  margin-bottom: vw(24px);
  max-width: vw(800px);
  overflow-wrap: break-word;
}

.subtitle {
  color: $font-color;
  font-size: vw(24px);
  line-height: vw(30px);
  margin-bottom: vw(24px);
  max-width: vw(742px);
}

.stadium {
  color: $font-color;
  font-size: vw(24px);
  line-height: vw(30px);
  margin-bottom: vw(20px);
  max-width: vw(900px);
}

.score-container {
  display: flex;
  gap: $metadata-spacing-score-spacing-sm;
  position: relative;
  width: vw(230px);

  .home-team-logo {
    background-repeat: no-repeat;
    background-size: contain;
    filter: drop-shadow(2px 2px 10px rgba(255, 255, 255, .35));
    filter: drop-shadow(vw(2px) vw(2px) vw(10px) rgba(255, 255, 255, .35));
    height: vw(70px);
    left: 0;
    position: absolute;
    top: 0;
    width: vw(70px);
  }

  .away-team-logo {
    background-repeat: no-repeat;
    background-size: contain;
    bottom: 0;
    filter: drop-shadow(2px 2px 10px rgba(255, 255, 255, .35));
    filter: drop-shadow(vw(2px) vw(2px) vw(10px) rgba(255, 255, 255, .35));
    height: vw(70px);
    left: 0;
    position: absolute;
    width: vw(70px);
  }

  .home-score {
    font-size: vw(56px);
    font-weight: 700;
    height: vw(80px);
    line-height: vw(80px);
    position: absolute;
    right: 0;
    text-align: center;
    top: 0;
    vertical-align: middle;
    width: vw(120px);
  }

  .away-score {
    bottom: 0;
    font-size: vw(56px);
    font-weight: 700;
    height: vw(80px);
    line-height: vw(80px);
    position: absolute;
    right: 0;
    text-align: center;
    vertical-align: middle;
    width: vw(120px);
  }
}

.button-container {
  display: flex;
  flex-direction: row;
  width: 100%;
}

.home-team-logo-container {
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(2px 2px 10px rgba(255, 255, 255, .35));
  filter: drop-shadow(vw(2px) vw(2px) vw(10px) rgba(255, 255, 255, .35));
  height: vw(263px);
  left: vw(1219px);
  position: absolute;
  top: vw(245px);
  width: vw(263px);
}

.vs-icon {
  background-repeat: no-repeat;
  background-size: contain;
  height: vw(38px);
  left: vw(1395px);
  position: absolute;
  top: vw(560px);
  width: vw(120px);
}

.away-team-logo-container {
  background-repeat: no-repeat;
  background-size: contain;
  filter: drop-shadow(2px 2px 10px rgba(255, 255, 255, .35));
  filter: drop-shadow(vw(2px) vw(2px) vw(10px) rgba(255, 255, 255, .35));
  height: vw(263px);
  left: vw(1432px);
  position: absolute;
  top: vw(629px);
  width: vw(263px);
}

.description-container {
  font-size: $body-medium-font-size;
  font-weight: 400;
  letter-spacing: $body-medium-letter-spacing;
  margin-bottom: 48px;
  margin-top: 16px;
  width: vw(800px);
}

.team-colors-container {
  height: 100%;
  left: vw(432px);
  opacity: .6;
  position: absolute;
  top: 0;
  width: vw(1484px);

  .home-primary {
    left: vw(763px);
    position: absolute;
    top: vw(191px);
  }

  .home-secondary {
    left: vw(624px);
    position: absolute;
    top: vw(246px);
  }

  .home-tertiary {
    left: vw(665px);
    position: absolute;
    top: vw(369px);
  }

  .home-quaternary {
    left: vw(1055px);
    position: absolute;
    top: vw(382px);
  }

  .away-primary {
    left: vw(1117px);
    position: absolute;
    top: vw(487px);
  }

  .away-secondary {
    left: vw(921px);
    position: absolute;
    top: vw(714px);
  }

  .away-tertiary {
    left: vw(1218px);
    position: absolute;
    top: vw(588px);
  }

  .away-quaternary {
    left: vw(886px);
    position: absolute;
    top: vw(730px);
  }
}
